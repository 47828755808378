import Logo from "./valobit.png";
import {
  AiOutlineCopy,
  AiOutlineSearch,
  AiOutlinePoweroff,
} from "react-icons/ai";
import { BsFillPersonFill } from "react-icons/bs";
import { MdGroups2 } from "react-icons/md";
import $ from "jquery";
import { useState, useEffect } from "react";
import Web3 from "web3";
import tokenABI from "./ABI/busd.json";
import sunflowerABI from "./ABI/vbitmlm.json";
import config from "./config/apiConfig";
import configs from "./config/config";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { AiOutlineAlignRight } from "react-icons/ai";
import copy from "copy-to-clipboard";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import axios from "axios";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import stakingabi from "./ABI/stakingabi";

$(function () {
  $(".genealogy-tree ul").hide();
  $(".genealogy-tree>ul").show();
  $(".genealogy-tree ul.active").show();
  $(".genealogy-tree li").on("click", function (e) {
    var children = $(this).find("> ul");
    if (children.is(":visible")) children.hide("fast").removeClass("active");
    else children.show("fast").addClass("active");
    e.stopPropagation();
  });
});

function App() {
  const navigate = useNavigate();

  const [userAddress, setUserAddress] = useState("0x00");
  const [directlength, setdirectlength] = useState(0);
  const [DirectIncome, setDirectIncome] = useState(0);
  const [LevelIncome, setLevelIncome] = useState(0);
  const [TotalEarning, setTotalEarning] = useState(0);
  const [referralidvalue, setreferralidvalue] = useState(0);
  const [StakeAmount, setStakeAmount] = useState(0);
  const [AllParticipant, setAllParticipant] = useState(0);
  const [inputSearchval, setinputSearchval] = useState("");
  const [ParentId, setParentId] = useState(0);
  const [genealogyData, setgenealogyData] = useState([]);
  const [nextDatasaddress, setnextDatasaddress] = useState([]);
  const [Parenttreeid, setParenttreeid] = useState();
  const [usrreward, setusrreward] = useState(0);

  const packamounts = async () => {
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    const getusraddr = await axios.get(
      `${configs.BACKEND_URL}userpackage?address=${accounts[0]}`
    );
    setStakeAmount(getusraddr.data.data.amount);
  };

  const findUserId = async () => {
    let web3js = new Web3(config.rpcUrls);
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    let stakeContract = new web3js.eth.Contract(
      stakingabi,
      config.stakingContract
    );
    let stak = await stakeContract.methods.getuserstake(accounts[0], 0).call();
    console.log("stak--------", stak);
    let rewardsamount = await web3js.utils.fromWei(stak[1], "ether");
    setusrreward(rewardsamount);

    let sunflowerContract = new web3js.eth.Contract(
      sunflowerABI,
      config.sunflowerContract
    );
    let allparti = await sunflowerContract.methods.lastUserId().call();

    let refidvalue = await sunflowerContract.methods.users(accounts[0]).call();

    setreferralidvalue(parseInt(refidvalue.id));
    setParentId(parseInt(refidvalue.id));
    setAllParticipant(parseInt(allparti) - 1);
    let directrefLen = await sunflowerContract.methods
      .directreferral(accounts[0])
      .call();
    setdirectlength(directrefLen.length);

    let directincome = await sunflowerContract.methods
      .directIncome(accounts[0])
      .call();
    let diretamount = await web3js.utils.fromWei(directincome, "ether");
    setDirectIncome(Number(diretamount));
    let lvlincome = await sunflowerContract.methods
      .levelIncome(accounts[0])
      .call();

    let lvlamount = await web3js.utils.fromWei(lvlincome, "ether");
    setLevelIncome(Number(lvlamount));
    setTotalEarning(Number(diretamount) + Number(lvlamount));
  };

  const findDirectref = async (addr) => {
    let web3js = new Web3(config.rpcUrls);
    let sunflowerContract = new web3js.eth.Contract(
      sunflowerABI,
      config.sunflowerContract
    );
    let directref = await sunflowerContract.methods.directreferral(addr).call();
    let refidvalueparent = await sunflowerContract.methods.users(addr).call();
    setParenttreeid(parseInt(refidvalueparent.id));
    let idvalue = [];
    let idvalue2 = [];
    let idvalue3 = [];
    for (let i = 0; i < directref.length; i++) {
      var getbenefit = {};
      let refidvalue = await sunflowerContract.methods
        .users(directref[i])
        .call();
      let directrefadd = await sunflowerContract.methods
        .directreferral(directref[i])
        .call();
      getbenefit["id"] = parseInt(refidvalue.id);
      getbenefit["address"] = directref[i];
      idvalue.push(getbenefit);
    }
    // setgenealogyData(idvalue)
    if (idvalue.length > 0) {
      for (let i = 0; i < idvalue.length; i++) {
        let refidvalueprev = await sunflowerContract.methods
          .users(idvalue[i].address)
          .call();
        let directrefadd2 = await sunflowerContract.methods
          .directreferral(idvalue[i].address)
          .call();
        if (directrefadd2.length > 0) {
          let refidvalue2 = await sunflowerContract.methods
            .users(directrefadd2[0])
            .call();
          const getbenefit2 = {
            id: parseInt(refidvalue2.id),
            address: directrefadd2[0],
            idvalueprev: parseInt(refidvalueprev.id),
          };
          idvalue2.push(getbenefit2);
        }
      }
    }
    let arr1Map = new Map(idvalue.map((item) => [item.id, item]));
    for (const item of idvalue2) {
      const parent = arr1Map.get(item.idvalueprev);
      if (parent) {
        if (!parent.children) {
          parent.children = [];
        }
        parent.children.push({ id: item.id });
      }
    }
    setgenealogyData(idvalue);

    if (idvalue2.length > 0) {
      for (let i = 0; i < idvalue2.length; i++) {
        let refidvalueprev = await sunflowerContract.methods
          .users(idvalue2[i].address)
          .call();
        let directrefadd2 = await sunflowerContract.methods
          .directreferral(idvalue2[i].address)
          .call();
        if (directrefadd2.length > 0) {
          let refidvalue2 = await sunflowerContract.methods
            .users(directrefadd2[0])
            .call();
          const getbenefit3 = {
            id: parseInt(refidvalue2.id),
            address: directrefadd2[0],
            idvalueprev: parseInt(refidvalueprev.id),
          };
          idvalue3.push(getbenefit3);
        }
      }
    }
    setgenealogyData(idvalue);
  };

  const dsts = [
    {
      id: Parenttreeid,
      children: genealogyData,
    },
  ];

  useEffect(() => {
    packamounts();
    let prms = localStorage.getItem("parentId");
    if (prms !== null) {
      localStorage.removeItem("parentId");
    }
    const gettreestru = async () => {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      findDirectref(accounts[0]);
      findUserId();
      let userMetaAddress = localStorage.getItem("address");
      if (userMetaAddress != null) {
        setUserAddress(
          userMetaAddress.substring(0, 5) +
            "..." +
            userMetaAddress.substring(userMetaAddress.length - 4)
        );
      }
    };
    gettreestru();
  }, []);

  const valobitlogout = async () => {
    localStorage.clear();
    navigate("/");
  };

  const copyLink = () => {
    let refLink = config.FRONTEND_URL + `network/${referralidvalue}`;
    copy(refLink);
    toast.success("Copied");
  };

  const searchChange = async (event) => {
    event.preventDefault();
    setinputSearchval(event.target.value);
  };

  const submitSearch = async (event) => {
    event.preventDefault();
    let inputVal = Number(inputSearchval);
    if (inputVal !== 0) {
      setParentId(inputVal);
      let web3js = new Web3(config.rpcUrls);
      let sunflowerContract = new web3js.eth.Contract(
        sunflowerABI,
        config.sunflowerContract
      );
      let idBasedAddress = await sunflowerContract.methods
        .idToAddress(inputVal)
        .call();
      console.log("idBasedAddress", idBasedAddress);
      findDirectref(idBasedAddress);
    } else {
      Swal.fire({
        background: "#1b1049",
        border: "1px solid #00e5ff21",
        color: "#ffffff",
        title: "There is no user",
      });
    }
  };

  const flashloan = async () => {
    Swal.fire({
      background: "#1b1049",
      border: "1px solid #00e5ff21",
      color: "#ffffff",
      title: "Coming Soon",
    });
  };

  const aggregator = async () => {
    Swal.fire({
      background: "#1b1049",
      border: "1px solid #00e5ff21",
      color: "#ffffff",
      title: "Coming Soon",
    });
  };

  const liquidpool = async () => {
    Swal.fire({
      background: "#1b1049",
      border: "1px solid #00e5ff21",
      color: "#ffffff",
      title: "Coming Soon",
    });
  };

  return (
    <div className="App">
      <ToastContainer />
      <nav class="navbar navbar-expand-lg">
        <div class="container-fluid col-lg-11">
          <a class="navbar-brand" href="/">
            <img src={Logo} alt="logo" />
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon">
              <HiOutlineMenuAlt3 className="text-white navbar-toggler-icon-new" />
            </span>
          </button>
          <ul class="navbar-nav me-auto mb-2 mb-lg-0 gap-3">
            <li class="nav-item">
              <button
                class="nav-link main-nav-connect-button"
                onClick={flashloan}
              >
                Flashloan
              </button>
            </li>
            <li class="nav-item">
              <button
                class="nav-link main-nav-connect-button"
                onClick={aggregator}
              >
                DEX Aggregator
              </button>
            </li>
            <li class="nav-item">
              <button
                class="nav-link main-nav-connect-button"
                onClick={liquidpool}
              >
                Liquidity Pool
              </button>
            </li>
          </ul>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto mb-2 mb-lg-0 gap-3">
              <li class="nav-item">
                <a class="nav-link main-nav-connect-button">{userAddress}</a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link main-nav-connect-button"
                  onClick={valobitlogout}
                >
                  <AiOutlinePoweroff
                    className="me-2"
                    style={{ fontSize: "18px" }}
                  />
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <section class="dashboard-values-design">
        <div class="container-fluid col-lg-11">
          <div className="d-flex flex-lg-row flex-column gap-lg-3 mb-3 mt-5">
            <div className="dasboard-tabs-section">
              <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    Dashboard
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    Pools
                  </button>
                </li>
              </ul>
            </div>
            <div className="ms-lg-auto text-lg-end">
              <p className="dashboard-text-2 mb-0 border-bottom-referral">
                Referral link :
                <span
                  className="ms-3 dashboard-text-3"
                  style={{ color: "#6a68ef" }}
                >
                  {config.FRONTEND_URL}network/{referralidvalue}
                </span>
                <a class="copy-button-dashboard" onClick={copyLink}>
                  <AiOutlineCopy />
                </a>
              </p>
            </div>
          </div>

          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
              tabindex="0"
            >
              <div class="row g-4">
                <div class="col-lg-3">
                  <div class="card dash-new-card">
                    <div class="card-body">
                      <div className="dash-border-bottom">
                        <p className="dashboard-text-4">
                          My ID {referralidvalue}
                        </p>
                      </div>
                      <div className="dash-border-bottom">
                        <p className="mt-3 mb-1 dashboard-text-4">
                          Direct Referral
                        </p>
                        <p className="dashboard-text-4">
                          <MdGroups2 className="me-3" />
                          <span>{directlength}</span>
                        </p>
                      </div>
                      <div className="mt-3">
                        <p className="mt-3 mb-2 dashboard-text-4-4">
                          Level Income
                        </p>
                        <p className="mb-0 dashboard-text-4">
                          {" "}
                          {LevelIncome} VUSD
                        </p>
                      </div>
                      <div className="mt-2">
                        <p className="mt-3 mb-2 dashboard-text-4-4">
                          Direct Income
                        </p>
                        <p className="mb-0 dashboard-text-4">
                          {" "}
                          {DirectIncome} VUSD
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-9">
                  <div className="row g-4">
                    <div className="col-lg-6">
                      <div class="card dash-new-card">
                        <div class="card-body py-lg-4 text-center">
                          <div className="">
                            <p className="dashboard-text-6">Total Earning</p>
                            <p className="dashboard-text-7">
                              {TotalEarning} VUSD
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div class="card dash-new-card">
                        <div class="card-body py-lg-4 text-center">
                          <div className="">
                            <p className="dashboard-text-6">Package 1</p>
                            <p className="dashboard-text-7">
                              {StakeAmount} VUSD
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row row-cols-1 row-cols-lg-3 mt-2 g-4 mb-5"></div>

                  <div className="row dashboard-search-section mb-3">
                    <div className="d-flex flex-lg-row align-items-center gap-3 justify-content-center">
                      <p className="mb-0">Search by ID</p>
                      <form
                        class="d-flex align-items-center overrall-search-id-section"
                        role="search"
                      >
                        <input
                          class="form-control"
                          type="search"
                          placeholder="Search"
                          aria-label="Search"
                          onChange={searchChange}
                          onKeyPress={(event) => {
                            const keyCode = event.which || event.keyCode;
                            const keyValue = String.fromCharCode(keyCode);

                            // Allow only numeric values (0-9) and the backspace key (8)
                            if (!/^[0-9\b.]+$/.test(keyValue)) {
                              event.preventDefault();
                            }
                          }}
                        />

                        {/* <input
                              type="text"
                              className="form-control"
                              value={wager}
                              aria-label="Example text with button addon"
                              aria-describedby="button-addon1"
                              onChange={handleWagerChange}
                              onKeyPress={(event) => {
                                const keyCode = event.which || event.keyCode;
                                const keyValue = String.fromCharCode(keyCode);

                                // Allow only numeric values (0-9) and the backspace key (8)
                                if (!/^[0-9\b.]+$/.test(keyValue)) {
                                  event.preventDefault();
                                }
                              }}
                              onPaste={handleWagerPaste}
                            /> */}

                        <button
                          class="border-0 bg-transparent text-white"
                          onClick={submitSearch}
                        >
                          <AiOutlineSearch />
                        </button>
                      </form>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <Tree data={dsts} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
              tabindex="0"
            >
              <div class="row g-4 justify-content-center">
                <div class="col-lg-6">
                  <h2 className="fw-bold text-center mb-4">Staking detail</h2>
                  <div class="card dash-new-card py-lg-5">
                    <div class="card-body">
                      <div className="row justify-content-evenly">
                        <div className="col-5">
                          <div className="d-flex">
                            <p className="mt-3 mb-0 dashboard-text-5">
                              Amount staked
                            </p>
                            <p className="mt-3 mb-0 dashboard-text-5 ms-auto">
                              :
                            </p>
                          </div>
                        </div>
                        <div className="col-5">
                          <p className="mt-3 mb-0 dashboard-text-5">
                            {StakeAmount * 2}
                          </p>
                        </div>
                      </div>
                      <div className="row justify-content-evenly">
                        <div className="col-5">
                          <div className="d-flex">
                            <p className="mt-3 mb-0 dashboard-text-5">
                              Locked Duration
                            </p>
                            <p className="mt-3 mb-0 dashboard-text-5 ms-auto">
                              :
                            </p>
                          </div>
                        </div>
                        <div className="col-5">
                          <p className="mt-3 mb-0 dashboard-text-5">
                            150 months
                          </p>
                        </div>
                      </div>
                      <div className="row justify-content-evenly">
                        <div className="col-5">
                          <div className="d-flex">
                            <p className="mt-3 mb-0 dashboard-text-5">
                              Total reward earned
                            </p>
                            <p className="mt-3 mb-0 dashboard-text-5 ms-auto">
                              :
                            </p>
                          </div>
                        </div>
                        <div className="col-5">
                          <p className="mt-3 mb-0 dashboard-text-5">
                            {usrreward}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;

const Tree = ({ data }) => {
  return (
    <div className="genealogy-body genealogy-scroll">
      <div className="genealogy-tree">
        <ul>
          {data.map((node) => (
            <TreeNode key={node.id} node={node} />
          ))}
        </ul>
      </div>
    </div>
  );
};

const TreeNode = ({ node }) => {
  const hasChildren = node.children && node.children.length > 0;
  const [showchildren, setShowChildren] = useState(true);

  const toggleShowChildren = () => {
    setShowChildren(!showchildren);
    $(`#node-${node.id} > ul`).toggle();
  };

  return (
    <li>
      <a href="javascript:void(0);">
        <div className="member-view-box" onClick={toggleShowChildren}>
          <div className="member-image">
            <div className="d-flex flex-row align-items-center justify-content-center">
              <div>
                <BsFillPersonFill class="tree-person-icon" />
              </div>
            </div>
            <div className="ms-auto text-center">
              <span className="">
                <span className="me-1">ID</span>
                {node.id}
              </span>
            </div>
          </div>
        </div>
      </a>

      {showchildren && hasChildren && (
        <ul>
          {node.children.map((child) => (
            <TreeNode key={child.id} node={child} />
          ))}
        </ul>
      )}
    </li>
  );
};

let config = {}
let environment = "live"
if (environment === "local") {
    config = {
        sunflowerContract: "0x6ce43DC451E450b256462a239E796A415BB56376",
        stakingContract: "0x9a6a4b855DF663b004F0ab6Cc35bB2CA25a6D736",
        busdcontract: "0xB1F366b980Ed61581F07EA8e79c7c1ad2B1680b5",
        FRONTEND_URL:"http://3.110.168.187/",
        chainId : "0x54d",
        rpcUrls : "https://rpc.bkcscan.io/",
        metmaskurl: "https://metamask.io/download/"
    }
}else if(environment === "deployment") {
    config = {
        sunflowerContract: "0x083bA4366950CE64d89F1Dd961aB32cadde18524",
        busdcontract: "0xb9A219631Aed55eBC3D998f17C3840B7eC39C0cc",
        FRONTEND_URL:"https://valobitfl.com/",
        chainId : "0x9e7",
        rpcUrls : "https://rpc.mnet.vblockchain.io/",
        metmaskurl: "https://metamask.io/download/",
        BINANCE_API:"https://api.binance.us/api/v1/ticker/24hr",
        API_URL:"https://api.valobitfl.com/web3matic",
        APP_API_URL:"https://valobitfl.com"
    }
}else{
    config = {
        sunflowerContract: "0x083bA4366950CE64d89F1Dd961aB32cadde18524",
        busdcontract: "0xb9A219631Aed55eBC3D998f17C3840B7eC39C0cc",
        FRONTEND_URL:"https://buyidolauchpad.d-ecosystem.io/",
        chainId : "0x9e7",
        rpcUrls : "https://bsc-dataseed1.binance.org/",
        metmaskurl: "https://metamask.io/download/",
        BINANCE_API:"https://api.binance.us/api/v1/ticker/24hr",
        API_URL:"https://buyidolauchpad-api.d-ecosystem.io/web3matic",
        APP_API_URL:"https://buyidolauchpad-api.d-ecosystem.io/"
    }
}

export default config;
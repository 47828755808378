import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";

import Home from "./Home";
import Dashboard from "./Dashboard";
import ProtectedRoute from "./auth/ProductedRoute";
import Network from "./Network";
import Bridge from "./Bridge";


import Register from "./Registration";
import $ from "jquery";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          {/* <Route exact path="/" element={<Home />} /> */}
          <Route exact path="/" element={<Bridge/>}/>
          {/* <Route path="/register" element={<ProtectedRoute> {" "} <Register />{" "}</ProtectedRoute>}/>
          <Route path="/register/:network/:id" element={<ProtectedRoute>{" "}<Register />{" "}</ProtectedRoute>}
          />
          <Route path="/register/:network" element={
              <ProtectedRoute>
                {" "}
                <Register />{" "}
              </ProtectedRoute>
            }
          />
          <Route path="/dashboard"
            element={
              <ProtectedRoute>
                {" "}
                <Dashboard />{" "}
              </ProtectedRoute>
            }
          />
          <Route path="/network" element={<ProtectedRoute>{" "}<Network />{" "}</ProtectedRoute>}/>
          <Route path="/network/:id" element={<Network />}/> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;

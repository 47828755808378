import Web3 from "web3";
import erc20Abi from "../ABI/busd";
import homebridgeAbi from "../ABI/homebridgeabi.json";
import Swal from "sweetalert2";
import axios from "axios";
import config from "../config/tokenaddressconfig";
import nconfig from "../config/config"

console.log("nconfig.BSC_ContarctLink",nconfig.BSC_ContarctLink)
export const transferbsctoken = async (data = {}) => {

  var web3js = new Web3(window.ethereum);
  const accounts = data.useraccount;
  const depositamount = data.deposits;
  const selecttoken = data.tokenselect;
  let transfertokenvibits = data.vbitamount;
  const btomfromcontract = new web3js.eth.Contract(erc20Abi, config.BSC_TOKEN_ADDRESS);
  let transfertokenvibit = web3js.utils.toWei(transfertokenvibits.toString(), "ether");
  const transfertoken = web3js.utils.toWei(depositamount, "ether");
  if (selecttoken == "USDT (BEP20)") {
    const transferfn = await btomfromcontract.methods.transfer(config.ADMIN_ADDRESS, transfertoken).send({ from: accounts });
    var networkname = "BSC";
    var networkcurrency = "USDT"
    var transferobjects = {
      accounts,
      networkname,
      transfertokenvibit,
      networkcurrency
    };
    const paymentstatus = transferfn.status;
    if (paymentstatus == true) {
      const dcoinget = await axios.post(config.API_URL, transferobjects);
      Swal.fire({
        icon: "success",
        background: "#041325",
        border: "1px solid #00e5ff21",
        color: "#ffffff",
        title: "Transaction confirmed",
        html:
          "Your transaction hash is:<br/><br/>" +
          `<a href=${nconfig.BSC_ContarctLink`/tx/` + dcoinget.data
          }  target="_blank" style="color:#03a2ff;">${dcoinget.data}</a>
            `,
      }).then(function () {
        window.location = "/";
      });
    } else {
      Swal.fire({
        icon: "error",
        background: "#041325",
        border: "1px solid #00e5ff21",
        color: "#ffffff",
        title: "Transaction Not confirmed",
        html: "Your transaction hash is Not confirmed",
      });
    }
  } else {
    const transferfn = await web3js.eth.sendTransaction({
      from: accounts,
      to: config.ADMIN_ADDRESS,
      value: transfertoken
    })
    var networkname = "BSC";
    var networkcurrency = "BSC";
    var transferobjects = {
      accounts,
      networkname,
      transfertokenvibit,
      networkcurrency
    };
    const paymentstatus = transferfn.status;
    if (paymentstatus == true) {
      const dcoinget = await axios.post(config.API_URL, transferobjects);
      Swal.fire({
        icon: "success",
        background: "#041325",
        border: "1px solid #00e5ff21",
        color: "#ffffff",
        title: "Transaction confirmed",
        html:
          "Your transaction hash is:<br/><br/>" +
          `<a href=${nconfig.BSC_ContarctLink`/tx/` + dcoinget.data
          }  target="_blank" style="color:#03a2ff;">${dcoinget.data}</a>`,
      }).then(function () {
        window.location = "/";
      });
    } else {
      Swal.fire({
        icon: "error",
        background: "#041325",
        border: "1px solid #00e5ff21",
        color: "#ffffff",
        title: "Transaction Not confirmed",
        html: "Your transaction hash is Not confirmed",
      });
    }
  }
};

export const transferethtoken = async (data = {}) => {
  var web3js = new Web3(window.ethereum);
  const accounts = data.useraccount;
  const depositamount = data.deposits;
  const selecttoken = data.tokenselect;
  let transfertokenvibits = data.vbitamount;
  const ethtokencontract = new web3js.eth.Contract(erc20Abi, config.ETH_TOKEN_ADDRESS);
  const decimals = await ethtokencontract.methods.decimals().call();
  const transfertoken = web3js.utils.toWei(depositamount, "mwei");
  let transfertokenvibit = web3js.utils.toWei(transfertokenvibits.toString(), "ether");
  console.log(decimals,"decimals");
  console.log('transfertokenvibit---', transfertoken);
 
  if (selecttoken == "USDT (ERC20)") {
    Swal.fire({
      title: "Transaction in Progress",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
        Swal.disableButtons();

      }
    });
    var transferfn;
    try {

       transferfn = await ethtokencontract.methods.transfer(config.ADMIN_ADDRESS, transfertoken).send({ from: accounts });
    } catch (error) {
      Swal.fire({
        icon: "warning",
        background: "#041325",
        border: "1px solid #00e5ff21",
        color: "#ffffff",
        title: "Transaction cancelled",
        
      }).then(function () {
        window.location = "/";
      });
    }
      var networkname = "ETH";
      var networkcurrency = "USDT"
      var transferobjects = {
        accounts,
        networkname,
        transfertokenvibit,
        networkcurrency
      };
      console.log('transferfn---', transferfn);
      const paymentstatus = transferfn.transactionHash;
      if (paymentstatus) {

        Swal.fire({
          title: "Transaction in Progress",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
            Swal.disableButtons();

          }
        });

      }
      if (transferfn.transactionHash) {
        const dcoinget = await axios.post(config.API_URL, transferobjects);
        console.log('dcoinget----', dcoinget);
        Swal.fire({
          icon: "success",
          background: "#041325",
          border: "1px solid #00e5ff21",
          color: "#ffffff",
          title: "Transaction confirmed",
          html:
            "Your transaction hash is:<br/><br/>" +
            `<a href=${nconfig.BSC_ContarctLink + dcoinget.data
            }  target="_blank" style="color:#03a2ff;">${dcoinget.data}</a>
            `,
        }).then(function () {
          window.location = "/";
        });
      }
    
  } else {
    const transferfn = await web3js.eth.sendTransaction({
      from: accounts,
      to: config.ADMIN_ADDRESS,
      value: transfertoken
    })

    var networkname = "ETH";
    var networkcurrency = "ETH";
    var transferobjects = {
      accounts,
      networkname,
      transfertokenvibit,
      networkcurrency
    };
    const paymentstatus = transferfn.status;
    if (paymentstatus == true) {
      const dcoinget = await axios.post(config.API_URL, transferobjects);
      Swal.fire({
        icon: "success",
        background: "#041325",
        border: "1px solid #00e5ff21",
        color: "#ffffff",
        title: "Transaction confirmed",
        html:
          "Your transaction hash is:<br/><br/>" +
          `<a href=${nconfig.BSC_ContarctLink + dcoinget.data
          }  target="_blank" style="color:#03a2ff;">${dcoinget.data}</a>`,
      }).then(function () {
        window.location = "/";
      });
    } else {
      Swal.fire({
        icon: "error",
        background: "#041325",
        border: "1px solid #00e5ff21",
        color: "#ffffff",
        title: "Transaction Not confirmed",
        html: "Your transaction hash is Not confirmed",
      });
    }
  }
};

export const transfermatictoken = async (data = {}) => {
  var web3js = new Web3(window.ethereum);
  const accounts = data.useraccount;
  const depositamount = data.deposits;
  const selecttoken = data.tokenselect;
  let transfertokenvibits = data.vbitamount;
  const matictokencontract = new web3js.eth.Contract(erc20Abi, config.MATIC_TOKEN_ADDRESS);
  const decimals = await matictokencontract.methods.decimals().call();
  const transfertoken = web3js.utils.toWei(depositamount, "mwei");
  let transfertokenvibit = web3js.utils.toWei(transfertokenvibits.toString(), "ether");
  if (selecttoken == "USDT") {
    const transferfn = await matictokencontract.methods.transfer(config.ADMIN_ADDRESS, transfertoken).send({ from: accounts });
    Swal.fire({
      icon: "warning",
      background: "#041325",
      border: "1px solid #00e5ff21",
      color: "#ffffff",
      title: "Transaction is Processing",
      html: "Do Not Refresh or Reload the Page",
      allowOutsideClick: false,
      showConfirmButton: false,
    });

    var networkname = "MATIC";
    var networkcurrency = "USDT"
    var transferobjects = {
      accounts,
      networkname,
      transfertokenvibit,
      networkcurrency
    };
    const paymentstatus = transferfn.status;
    if (paymentstatus == true) {
      const dcoinget = await axios.post(config.API_URL, transferobjects);
      Swal.fire({
        icon: "success",
        background: "#041325",
        border: "1px solid #00e5ff21",
        color: "#ffffff",
        title: "Transaction confirmed",
        html:
          "Your transaction hash is:<br/><br/>" +
          `<a href=${nconfig.BSC_ContarctLink + dcoinget.data
          }  target="_blank" style="color:#03a2ff;">${dcoinget.data}</a>`,
      }).then(function () {
        window.location = "/";
      });
    } else {
      Swal.fire({
        icon: "error",
        background: "#041325",
        border: "1px solid #00e5ff21",
        color: "#ffffff",
        title: "Transaction Not confirmed",
        html: "Your transaction hash is Not confirmed",
      });
    }
  } else {
    Swal.fire({
      icon: "warning",
      background: "#041325",
      border: "1px solid #00e5ff21",
      color: "#ffffff",
      title: "Transaction is Processing",
      html: "Do Not Refresh or Reload the Page",
      allowOutsideClick: false,
      showConfirmButton: false,
    });

    const transferfn = await web3js.eth.sendTransaction({
      from: accounts,
      to: config.ADMIN_ADDRESS,
      value: transfertoken
    })
    var networkname = "MATIC";
    var networkcurrency = "MATIC";
    var transferobjects = {
      accounts,
      networkname,
      transfertokenvibit,
      networkcurrency
    };
    const paymentstatus = transferfn.status;
    if (paymentstatus == true) {
      const dcoinget = await axios.post(config.API_URL, transferobjects);
      Swal.fire({
        icon: "success",
        background: "#041325",
        border: "1px solid #00e5ff21",
        color: "#ffffff",
        title: "Transaction confirmed",
        html:
          "Your transaction hash is:<br/><br/>" +
          `<a href=${nconfig.BSC_ContarctLink + dcoinget.data
          }  target="_blank" style="color:#03a2ff;">${dcoinget.data}</a>
        `,
      }).then(function () {
        window.location = "/";
      });
    } else {
      Swal.fire({
        icon: "error",
        background: "#041325",
        border: "1px solid #00e5ff21",
        color: "#ffffff",
        title: "Transaction Not confirmed",
        html: "Your transaction hash is Not confirmed",
      });
    }
  }
};

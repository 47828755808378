import { useState, useEffect } from "react";
import Logo from "./valobit.png";
import { IoWallet } from "react-icons/io5";
import './Style.css';
import { AiOutlineInfoCircle, AiOutlineCopy } from 'react-icons/ai';
import { HiArrowDown } from 'react-icons/hi';
import { ImArrowRight2 } from 'react-icons/im';
import $ from 'jquery';
import Web3 from 'web3'
import  erc20Abi  from './ABI/busd'
import  homebridgeAbi from './ABI/homebridgeabi.json'
import Swal from 'sweetalert2'
import bscimage from './assets/images/bscimages.svg.png'
import maticimg from './assets/images/polygon.svg'
import ethimg from './assets/images/Eth.png'
import USDTimg from './assets/images/usdt.png'
import dchainimg from './assets/images/dchainimages.png'
import Navigate from "./components/navigate";
import Marquee from "react-fast-marquee";
import { transferbsctoken, transferethtoken, transfermatictoken } from './transferbridge/transferbsc';
import config from './config/tokenaddressconfig';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineUserAdd, AiFillTwitterCircle,AiOutlineAlignRight } from "react-icons/ai";
import Globe from "./assets/images/banner-globe.gif";
import Card1 from "./assets/images/global-decentralized-ecosystem.png";
import Card2 from "./assets/images/smart-contract-blockchain.png";
import Card3 from "./assets/images/web3-automated-system.png";
import Card4 from "./assets/images/funds-safe-here.png";
import "react-toastify/dist/ReactToastify.css";
import backendConfig from './config/config'
import ReferralIncome from "./assets/images/ReferralIncome.png";
import LevelIncome from "./assets/images/LevelIncome.png";
import sunflowerABI from "./ABI/vbitmlm.json";
import tokenABI from "./ABI/busd.json";
import { useNavigate } from "react-router-dom";
import { BiLogoFacebook } from "react-icons/bi";
import { AiOutlineTwitter } from "react-icons/ai";
import { FaTelegramPlane } from "react-icons/fa";
import MetaMaskAuth from './metamask';
import axios from 'axios';
import moment from 'moment';
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal, useWeb3Modal } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { arbitrum, mainnet, polygon } from "wagmi/chains";
import { Web3Button } from "@web3modal/react";
import { SignEthereumMessageResponse } from "@coinbase/wallet-sdk/dist/relay/Web3Response";


function Bridge() {

  const [depositamount, setdepositamount] = useState(0);
  const [dropdownvalue, setdropdownvalue] = useState("");
  const [bridgesoption, setbridgesoption] = useState([ { value: 1, label: "USDT" }]);
  const [networkrpc, setnetworkrpc] = useState("");
  const [networkchainid, setnetworkchainid] = useState("");
  const [accountaddress, setaccountaddress] = useState("");
  const [chainname, setchainname] = useState("");
  const [bscchain, setbscchain] = useState("BSC");
  const [selecttoken, setselecttoken] = useState("0");
  const [tokenbalance, settokenbalance] = useState(0);
  const [tokensymbol, settokensymbol] = useState("ETH");
  const [networklogo, setnetworklogo] = useState(ethimg);

  const [bscbal, setbscbal] = useState('0');

  const [bridgenetworklogo, setbridgenetworklogo] = useState(dchainimg);
  const [bridgenetworksymbol, setbridgenetworksymbol] = useState('TPN');
  const [network, setnetwork] = useState();
  const [dcxamountvalue, setdcxamountvalue] = useState(0);
  const [show, setShow] = useState(true);
  const [showError, setShowError] = useState("")
  const [message, setmessage] = useState("")


  const rewardset = async (event) => {
    setdropdownvalue(event.target.value)
    console.log("event.target.value", event.target.value)
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    const chainid = await window.ethereum.request({ method: "eth_chainId" });
    sessionStorage.setItem('chainname', "metamask")
    sessionStorage.setItem('chainaccounts', accounts[0])
    if (chainid == '0x38') {
      if (event.target.value == "BSC") {
        setdepositamount(0)
        setbscchain("BSC")
        settokensymbol("BSC")
        var web3js = new Web3(config.bsctestnetrpcurl);
        const balance = await web3js.eth.getBalance(accounts[0])
        const balanceseth = web3js.utils.fromWei(balance, 'ether');
        const tokenbalance = parseFloat(balanceseth).toFixed(3);

        var web3dchainjs = new Web3(config.valobit);
        const dchaincontract = new web3dchainjs.eth.Contract(erc20Abi, config.SEPOLIA_BATTOKEN_ADDRESS);
        const dchainbalance = await dchaincontract.methods.balanceOf(accounts[0]).call();
        const balancesdchain = web3js.utils.fromWei(dchainbalance, 'ether');
        const balancesdchainfixed = Number(balancesdchain).toFixed(8)
        setselecttoken(event.target.value)
        settokenbalance(tokenbalance)
        setbscbal(balancesdchainfixed)
        setbridgenetworksymbol("TPN")

      }
      else {
        setdepositamount(0)
        setbscchain("BSC")
        const web3js = new Web3(config.bsctestnetrpcurl);
        const btomfromcontract = new web3js.eth.Contract(erc20Abi, config.BSC_TOKEN_ADDRESS);
        const weibalance = await btomfromcontract.methods.balanceOf(accounts[0]).call();
        const balances = web3js.utils.fromWei(weibalance, 'ether');
        const tokenbalance = parseFloat(balances).toFixed(2);
        settokenbalance(tokenbalance)
        const symbol = await btomfromcontract.methods.symbol().call()


        settokensymbol("USDT")
        const web3dchainjs = new Web3(config.valobit);
        const dchaincontract = new web3dchainjs.eth.Contract(erc20Abi, config.SEPOLIA_BATTOKEN_ADDRESS);
        const valbalance = await dchaincontract.methods.balanceOf(accounts[0]).call();
        const balancesdchain = web3js.utils.fromWei(valbalance, 'ether');
        const balancesdchainfixed = Number(balancesdchain).toFixed(8)

        setselecttoken(event.target.value)
        setbscbal(balancesdchainfixed)
        setbridgenetworksymbol("TPN")
      }
    }
    else if (chainid == '0x89') {
      if (event.target.value == "MATIC") {
        setdepositamount(0)
        setbscchain("MATIC")
        settokensymbol("MATIC")
        var web3js = new Web3(config.polygonurl);
        const balance = await web3js.eth.getBalance(accounts[0])
        const balancesmatic = web3js.utils.fromWei(balance, 'ether');
        const tokenbalance = parseFloat(balancesmatic).toFixed(3);
        var web3dchainjs = new Web3(config.valobit);
        const dchaincontract = new web3dchainjs.eth.Contract(erc20Abi, config.SEPOLIA_BATTOKEN_ADDRESS);
        const dchainbalance = await dchaincontract.methods.balanceOf(accounts[0]).call();
        const balancesdchain = web3js.utils.fromWei(dchainbalance, 'ether');
        const balancesdchainfixed = Number(balancesdchain).toFixed(8)
        setselecttoken(event.target.value)
        settokenbalance(tokenbalance)
        setbscbal(balancesdchainfixed)
        setbridgenetworksymbol("TPN")
      }
      else {
        setdepositamount(0)
        setbscchain("MATIC")
        settokensymbol("USDT")

        var web3js = new Web3(config.polygonurl);
        const btomfromcontract = new web3js.eth.Contract(erc20Abi, config.MATIC_TOKEN_ADDRESS);
        const weibalance = await btomfromcontract.methods.balanceOf(accounts[0]).call();
        const balances = web3js.utils.fromWei(weibalance, 'ether');
        const tokenbalance = parseFloat(balances).toFixed(2);
        const symbol = await btomfromcontract.methods.symbol().call()
        settokenbalance(tokenbalance)

        var web3dchainjs = new Web3(config.valobit);
        const dchaincontract = new web3dchainjs.eth.Contract(erc20Abi, config.SEPOLIA_BATTOKEN_ADDRESS);
        const dchainbalance = await dchaincontract.methods.balanceOf(accounts[0]).call();
        const balancesdchain = web3js.utils.fromWei(dchainbalance, 'mwei');
        const balancesdchainfixed = Number(balancesdchain).toFixed(8)

        setselecttoken(event.target.value)
        setbscbal(balancesdchainfixed)
        setbridgenetworksymbol("TPN")
      }
    }
    else if (chainid == '0x1') {
      if (event.target.value == "ETH") {
        setdepositamount(0)
        setbscchain("ETH")
        settokensymbol("ETH")
        var web3js = new Web3(config.web3dchainurl);
        const balance = await web3js.eth.getBalance(accounts[0])
        const balanceseth = web3js.utils.fromWei(balance, 'ether');
        const tokenbalance = parseFloat(balanceseth).toFixed(4);
        var web3dchainjs = new Web3(config.valobit);
        const dchaincontract = new web3dchainjs.eth.Contract(erc20Abi, config.SEPOLIA_BATTOKEN_ADDRESS);
        const dchainbalance = await dchaincontract.methods.balanceOf(accounts[0]).call();
        const balancesdchain = web3js.utils.fromWei(dchainbalance, 'ether');
        const balancesdchainfixed = Number(balancesdchain).toFixed(8)

        setselecttoken(event.target.value)
        settokenbalance(tokenbalance)
        setbscbal(balancesdchainfixed)
        setbridgenetworksymbol("TPN")
      }
      else {
        setdepositamount(0)
        setbscchain("ETH")

        var web3js = new Web3(config.web3dchainurl);
        const btomfromcontract = new web3js.eth.Contract(erc20Abi, config.ETH_TOKEN_ADDRESS);
        const weibalance = await btomfromcontract.methods.balanceOf(accounts[0]).call();
        const balances = web3js.utils.fromWei(weibalance, 'mwei');
        const tokenbalance = parseFloat(balances).toFixed(2);
        const symbol = await btomfromcontract.methods.symbol().call()
        settokenbalance(tokenbalance)

        settokensymbol("USDT")
        var web3dchainjs = new Web3(config.valobit);
        const dchaincontract = new web3dchainjs.eth.Contract(erc20Abi, config.SEPOLIA_BATTOKEN_ADDRESS);
        const dchainbalance = await dchaincontract.methods.balanceOf(accounts[0]).call();
        const balancesdchain = web3js.utils.fromWei(dchainbalance, 'ether');
        const balancesdchainfixed = Number(balancesdchain).toFixed(8)

        setselecttoken(event.target.value)
        setbscbal(balancesdchainfixed)
        setbridgenetworksymbol("TPN")
      }
    }
    else {
      console.log("wrong network")
    }
  }

  async function bridges() {
    var web3js = new Web3(window.ethereum);
    const chainid = await window.ethereum.request({ method: "eth_chainId" });
    const chainname = sessionStorage.getItem('chainname');
    setchainname(chainname)
    if (chainid == '0x38') {
      $('.switch-tabs-section-eth').css('background', 'transparent');
      $('.switch-tabs-section-eth').css('color', '#FFF');
      $('.switch-tabs-section-bsc').css('background', '#1b9ffe');
      $('.switch-tabs-section-bsc').css('color', '#FFF');
      $('.switch-tabs-section-poly').css('background', 'transparent');
      $('.switch-tabs-section-poly').css('color', '#FFF');
      settokensymbol('BSC')
      setnetworklogo(bscimage)
      const bridgeOptions = [
        // { value: 1, label: "BSC" },
        { value: 1, label: "USDT (BEP20)" }
      ]
      setbridgesoption(bridgeOptions)
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      var web3js = new Web3(config.bsctestnetrpcurl);
      const balance = await web3js.eth.getBalance(accounts[0])
      const balanceseth = web3js.utils.fromWei(balance, 'ether');
      const tokenbalance = parseFloat(balanceseth).toFixed(3);
      settokenbalance(tokenbalance)
      var web3dchainjs = new Web3(config.valobit);
      const dchaincontract = new web3dchainjs.eth.Contract(erc20Abi, config.SEPOLIA_BATTOKEN_ADDRESS);
      const dchainbalance = await dchaincontract.methods.balanceOf(accounts[0]).call();
      const balancesdchain = web3js.utils.fromWei(dchainbalance, 'ether');
      const balancesdchainfixed = Number(balancesdchain).toFixed(8)
      setbscbal(balancesdchainfixed)
    }
    else if (chainid == "0x1") {
      $('.switch-tabs-section-eth').css('background', '#1b9ffe');
      $('.switch-tabs-section-eth').css('color', '#FFF');
      $('.switch-tabs-section-bsc').css('background', 'transparent');
      $('.switch-tabs-section-bsc').css('color', '#FFF');
      $('.switch-tabs-section-poly').css('background', 'transparent');
      $('.switch-tabs-section-poly').css('color', '#FFF');
      settokensymbol('ETH')
      setnetworklogo(ethimg)
      const bridgeOptions = [
        // { value: 1, label: "ETH" },
        { value: 1, label: "USDT (ERC20)" }
      ]
      setbridgesoption(bridgeOptions)
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      var web3js = new Web3(config.valobit);
      const balance = await web3js.eth.getBalance(accounts[0])
      const balanceseth = web3js.utils.fromWei(balance, 'ether');
      const tokenbalance = parseFloat(balanceseth).toFixed(4);
      settokenbalance(tokenbalance)
      var web3dchainjs = new Web3(config.valobit);
      const dchaincontract = new web3dchainjs.eth.Contract(erc20Abi, config.SEPOLIA_BATTOKEN_ADDRESS);
      const dchainbalance = await dchaincontract.methods.balanceOf(accounts[0]).call();
      const balancesdchain = web3js.utils.fromWei(dchainbalance, 'ether');
      const balancesdchainfixed = Number(balancesdchain).toFixed(8)
      setbscbal(balancesdchainfixed)
    }

    else if (chainid == "0x89") {
      $('.switch-tabs-section-eth').css('background', 'transparent');
      $('.switch-tabs-section-eth').css('color', '#FFF');
      $('.switch-tabs-section-bsc').css('background', 'transparent');
      $('.switch-tabs-section-bsc').css('color', '#FFF');
      $('.switch-tabs-section-poly').css('background', '#1b9ffe');
      $('.switch-tabs-section-poly').css('color', '#FFF');
      settokensymbol("MATIC")
      setnetworklogo(maticimg)
      const bridgeOptions = [
        // { value: 1, label: "MATIC" },
        { value: 1, label: "USDT" }
      ]
      setbridgesoption(bridgeOptions)

      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      var web3js = new Web3(config.polygonurl);
      const balance = await web3js.eth.getBalance(accounts[0])
      const balancesmatic = web3js.utils.fromWei(balance, 'ether');
      const tokenbalance = parseFloat(balancesmatic).toFixed(3);
      settokenbalance(tokenbalance)
      var web3dchainjs = new Web3(config.valobit);
      const dchaincontract = new web3dchainjs.eth.Contract(erc20Abi, config.SEPOLIA_BATTOKEN_ADDRESS);
      const dchainbalance = await dchaincontract.methods.balanceOf(accounts[0]).call();
      const balancesdchain = web3js.utils.fromWei(dchainbalance, 'ether');
      const balancesdchainfixed = Number(balancesdchain).toFixed(8)
      setbscbal(balancesdchainfixed)
    }
    else {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: web3js.utils.toHex('0x1') }]
      });
    }
  }

  //bsc
  const metamask = async () => {
    const chainname = sessionStorage.getItem('chainname');
    setchainname(chainname)
    setdcxamountvalue(0)
    setdropdownvalue("")
    settokenbalance(0)
    settokensymbol("BSC")
    setdepositamount(0)
    setnetworklogo(bscimage)
    setnetworkrpc(config.bsctestnetrpcurl)
    setnetworkchainid("56")
    const bridgeOptions = [
      // { value: 1, label: "BSC" },
      { value: 1, label: "USDT (BEP20)" }
    ]
    setbridgesoption(bridgeOptions)
    var web3js = new Web3(window.ethereum);
    if (chainname == "metamask" || chainname == "coinbase") {
      const chainId = 0x38;
      const currentchainId = await window.ethereum.request({ method: "eth_chainId" });
      const curchain = web3js.utils.toHex(chainId)
      if (currentchainId != curchain) {
        const chainIdnumber = '56';
        const rpcURL = config.bsctestnetrpcurl;
        const networkName = 'BSC Smart Chain';
        const currencyName = 'BNB';
        const currencySymbol = 'BNB';
        const explorerURL = 'https://testnet.bscscan.com/';
        const chainId = await web3js.utils.toHex(chainIdnumber);
          console.log("chainId--",chainId)

        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: '0x38',
              chainName: networkName,
              rpcUrls: [rpcURL],
              blockExplorerUrls: [explorerURL],

              nativeCurrency: {
                name: currencyName,
                symbol: currencySymbol,
                decimals: 18,
              },
            },
          ],
        });
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x38' }]
        });

        var web3dchainjs = new Web3(config.valobit);
        const dchaincontract = new web3dchainjs.eth.Contract(erc20Abi, config.SEPOLIA_BATTOKEN_ADDRESS);
        const dchainbalance = await dchaincontract.methods.balanceOf(accounts[0]).call();
        const balancesdchain = web3js.utils.fromWei(dchainbalance, 'ether');
        const balancesdchainfixed = Number(balancesdchain).toFixed(8)

        var web3js = new Web3(config.bsctestnetrpcurl);
        const balance = await web3js.eth.getBalance(accounts[0])
        const balanceseth = web3js.utils.fromWei(balance, 'ether');
        const tokenbalance = parseFloat(balanceseth).toFixed(3);
        settokenbalance(tokenbalance)

        setaccountaddress(accounts[0])
        setbscbal(balancesdchainfixed)
        setbridgenetworklogo(dchainimg)
        setnetwork("BSC")
        setbridgenetworksymbol("TPN")
      }
      else {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        var web3dchainjs = new Web3(config.valobit);
        const dchaincontract = new web3dchainjs.eth.Contract(erc20Abi, config.SEPOLIA_BATTOKEN_ADDRESS);
        const dchainbalance = await dchaincontract.methods.balanceOf(accounts[0]).call();
        const balancesdchain = web3js.utils.fromWei(dchainbalance, 'ether');
        const balancesdchainfixed = Number(balancesdchain).toFixed(8)
        var web3js = new Web3(config.bsctestnetrpcurl);
        const balance = await web3js.eth.getBalance(accounts[0])
        const balanceseth = web3js.utils.fromWei(balance, 'ether');
        const tokenbalance = parseFloat(balanceseth).toFixed(3);
        settokenbalance(tokenbalance)
        setaccountaddress(accounts[0])
        setbscbal(balancesdchainfixed)
        setbridgenetworklogo(dchainimg)
        setnetwork("bsc")
        setbridgenetworksymbol("TPN")
      }
    }
    else if (chainname == null) {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      setaccountaddress(accounts[0])
      sessionStorage.setItem('chainname', "metamask")
      sessionStorage.setItem('chainaccounts', accounts[0])
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: web3js.utils.toHex('0x38') }]
      });
      var web3js = new Web3(config.bsctestnetrpcurl);
      const balance = await web3js.eth.getBalance(accounts[0])
      const balanceseth = web3js.utils.fromWei(balance, 'ether');
      const tokenbalance = parseFloat(balanceseth).toFixed(3);
      settokenbalance(tokenbalance)
    }
    else {
      console.log("")
    }
  }

  const getmaxvaluecontract = async (event) => {
    const gettrxusdt = await axios.get(config.BINANCE_API)

    const currentchainId = await window.ethereum.request({ method: "eth_chainId" });
    if (currentchainId == "0x38" && tokensymbol == "BSC") {
      const trnbscdcoin = ((event * gettrxusdt.data[12].lastPrice))
      setdcxamountvalue(trnbscdcoin);
      // const minimumamt = event * gettrxusdt.data[12].lastPrice
      setShow(false)
      setShowError(false)
    }
    else if (currentchainId == "0x38" && tokensymbol == "USDT") {
      const dcxamount = event / 1;
      setdcxamountvalue(dcxamount)
      setShow(false)
      setShowError(false)
    }
    else if (currentchainId == "0x89" && tokensymbol == "MATIC") {
      const trnmaticdcoin = (event * gettrxusdt.data[140].lastPrice)
      setdcxamountvalue(trnmaticdcoin)
      // const minimumamt = event * gettrxusdt.data[140].lastPrice
      setShow(false)
      setShowError(false)
    }
    else if (currentchainId == "0x89" && tokensymbol == "USDT") {
      const dcxamount = event / 1;
      setdcxamountvalue(dcxamount)
      setShow(false)
      setShowError(false)
    }
    else if (currentchainId === "0x1" && tokensymbol == "ETH") {
      // const minimumamt = event * gettrxusdt.data[7].lastPrice
      const trnethdcoin = ((event * gettrxusdt.data[7].lastPrice))
      setdcxamountvalue(trnethdcoin)
      setShow(false)
      setShowError(false)
    }
    else if (currentchainId == "0x1" && tokensymbol == "USDT") {
      const dcxamount = event / 1;
      setdcxamountvalue(dcxamount)
      setShow(false)
      setShowError()
    }
    else {
      console.log("something went wrong")
    }
  }

  const gettovaluecontract = async (event) => {
    event.preventDefault()
    setdcxamountvalue(event.target.value);
    const gettrxusdt = await axios.get(config.BINANCE_API);
    const currentchainId = await window.ethereum.request({ method: "eth_chainId" });
    if (currentchainId == "0x38" && tokensymbol == "BSC") {
      const trnbscdcoin = (event.target.value / gettrxusdt.data[12].lastPrice)
      setdepositamount(trnbscdcoin.toString())
      setShow(false)
      setShowError(false)
    }
    else if (currentchainId == "0x38" && tokensymbol == "USDT") {
      const dcxamount = event.target.value / 1.01010101;
      setdepositamount(dcxamount.toString());
      setShow(false)
      setShowError()
    }
    else if (currentchainId == "0x89" && tokensymbol == "MATIC") {
      const trnmaticdcoin = (event.target.value / gettrxusdt.data[140].lastPrice)
      setdepositamount(trnmaticdcoin.toString())
      setShow(false)
      setShowError(false)
    }
    else if (currentchainId == "0x89" && tokensymbol == "USDT") {
      const dcxamount = event.target.value / 1.01010101;
      setdepositamount(dcxamount.toString());
      setShow(false)
      setShowError()
    }
    else if (currentchainId === "0x1" && tokensymbol == "ETH") {
      // const trnethdcoin = (event.target.value / gettrxusdt.data[7].lastPrice)
      const trnethdcoin = event.target.value;
      console.log('trnethdcoin---', trnethdcoin);
      setdepositamount(trnethdcoin.toString());
      setShow(false)
      setShowError(false)
    }
    else if (currentchainId == "0x1" && tokensymbol == "USDT") {
      const dcxamount = event.target.value / 1.01010101;
      console.log(dcxamount);
      setdepositamount(dcxamount.toString());
      setShow(false)
      setShowError()
    }
    else {
      console.log("Something went wrong")
    }
  }



  const getfromvaluecontract = async (event) => {
    event.preventDefault()
    console.log(event.target.value,"value....")
    
    setdepositamount(event.target.value)
    // if(event.target.value <50 ){
    //   setmessage("Minimum amount 50");
    //   return false;
    // }else if(event.target.value > 50000){
    //   setmessage("Maximum selling amount 50000");
    //   return false;
    // }
    setmessage("")
    const gettrxusdt = await axios.get(config.BINANCE_API)
    const currentchainId = await window.ethereum.request({ method: "eth_chainId" });
    if (currentchainId == "0x38" && tokensymbol == "BSC") {
      const trnbscdcoin = ((event.target.value * gettrxusdt.data[12].lastPrice))
      setdcxamountvalue(trnbscdcoin);
      setShow(false)
      setShowError(false)
    }
    else if (currentchainId == "0x38" && tokensymbol == "USDT") {
     const dcxamount = event.target.value * 111.1111111;
      setdcxamountvalue(dcxamount.toFixed(2))
      setShow(false)
      setShowError(false)
    }
    else if (currentchainId == "0x89" && tokensymbol == "MATIC") {
      const trnmaticdcoin = ((event.target.value * gettrxusdt.data[140].lastPrice))
      setdcxamountvalue(trnmaticdcoin)
      setShow(false)
      setShowError(false)
    }
    else if (currentchainId == "0x89" && tokensymbol == "USDT") {
     const dcxamount = event.target.value * 111.1111111;
      setdcxamountvalue(dcxamount.toFixed(2))
      setShow(false)
      setShowError(false)
    }
    else if (currentchainId === "0x1" && tokensymbol == "ETH") {
      console.log('event.target.value---', event.target.value);

      const trnethdcoin = ((event.target.value * gettrxusdt.data[7].lastPrice))
      setdcxamountvalue(trnethdcoin)
      setShow(false)
      setShowError(false)
    }
    else if (currentchainId == "0x1" && tokensymbol == "USDT") {
      const dcxamount = event.target.value * 111.1111111;
     
      setdcxamountvalue(dcxamount.toFixed(2))
      setShow(false)
      setShowError()
    }
    else {
      console.log("something went wrong")
    }
  }


  const transfer = async (e) => {
    // if(depositamount <50 ){
    //   return false;
    // }else if(depositamount > 50000){
    //   return false;
    // }
    const userAddress = sessionStorage.getItem("chainaccounts")
    var web3js = new Web3(window.ethereum);
    const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
    const chainid = await window.ethereum.request({ method: "eth_chainId" });
    const accounts = account[0]

    if (chainid == "0x38") {
      const setdatas = {
        useraccount: accounts,
        deposits: depositamount,
        tokenselect: selecttoken,
        vbitamount : dcxamountvalue
      };
      await transferbsctoken(setdatas);
    }
    else if (chainid == "0x89") {
      const setdatas = {
        useraccount: accounts,
        deposits: depositamount,
        tokenselect: selecttoken,
        vbitamount : dcxamountvalue
      };
      await transfermatictoken(setdatas);
    }
    else if (chainid == "0x1") {
      const setdatas = {
        useraccount: accounts,
        deposits: depositamount,
        tokenselect: selecttoken,
        vbitamount : dcxamountvalue
      };
      console.log('setdatas---', setdatas);
      await transferethtoken(setdatas);
      
    }
    else {
      console.log("wrong network")
    }
  }

  // Polygon
  const maticnetworkbridge = async () => {
    setdcxamountvalue(0)
    setdropdownvalue("")
    settokenbalance(0)
    setdepositamount(0)
    setnetworklogo(maticimg)
    settokensymbol("MATIC")
    const maticbridgeOptions = [
      // { value: 1, label: "MATIC" },
      { value: 1, label: "USDT" }
    ]
    setbridgesoption(maticbridgeOptions)
    var web3js = new Web3(window.ethereum);
    if (chainname == "metamask" || chainname == "coinbase") {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const chainId = 0x89;
      const currentchainId = window.ethereum.chainId;
      const curchain = web3js.utils.toHex(chainId)
      if (currentchainId != curchain) {
        const chainIdnumber = '137';
        const rpcURL = config.polygonurl;
        const networkName = 'MATIC Smart Chain';
        const currencyName = 'MATIC';
        const currencySymbol = 'MATIC';
        const explorerURL = 'https://polygonscan.com/';
        const chainId = await web3js.utils.toHex(chainIdnumber);

        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: '0x89',
              chainName: networkName,
              rpcUrls: [rpcURL],
              blockExplorerUrls: [explorerURL],

              nativeCurrency: {
                name: currencyName,
                symbol: currencySymbol,
                decimals: 18,
              },
            },
          ],
        });
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x89' }]
        });
        var web3js = new Web3(config.polygonurl);
        const balance = await web3js.eth.getBalance(accounts[0])
        const balancesmatic = web3js.utils.fromWei(balance, 'ether');
        const tokenbalance = parseFloat(balancesmatic).toFixed(3);
        settokenbalance(tokenbalance)

        var web3dchainjs = new Web3(config.valobit);
        const dchaincontract = new web3dchainjs.eth.Contract(erc20Abi, config.SEPOLIA_BATTOKEN_ADDRESS);
        const dchainbalance = await dchaincontract.methods.balanceOf(accounts[0]).call();
        const balancesdchain = web3js.utils.fromWei(dchainbalance, 'ether');
        const balancesdchainfixed = Number(balancesdchain).toFixed(8)

        setbscbal(balancesdchainfixed)
        setbridgenetworklogo(dchainimg)
        setaccountaddress(accounts[0])
        setbridgenetworksymbol("TPN")
      }
      else {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        sessionStorage.setItem('chainname', "metamask")
        sessionStorage.setItem('chainaccounts', accounts[0])
        var web3dchainjs = new Web3(config.valobit);
        const dchaincontract = new web3dchainjs.eth.Contract(erc20Abi, config.SEPOLIA_BATTOKEN_ADDRESS);
        const dchainbalance = await dchaincontract.methods.balanceOf(accounts[0]).call();
        const balancesdchain = web3js.utils.fromWei(dchainbalance, 'ether');
        const balancesdchainfixed = Number(balancesdchain).toFixed(8)

        setnetworklogo(maticimg)
        setbscbal(balancesdchainfixed)
        setbridgenetworklogo(dchainimg)
        setnetwork("MATIC")
        settokensymbol("USDT")
        setaccountaddress(accounts[0])
        setbridgenetworksymbol("TPN")
      }
    }
    else if (chainname == null) {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      setaccountaddress(accounts[0])
      var web3js = new Web3(config.polygonurl);
      const balance = await web3js.eth.getBalance(accounts[0])
      const balancesmatic = web3js.utils.fromWei(balance, 'ether');
      const tokenbalance = parseFloat(balancesmatic).toFixed(3);
      settokenbalance(tokenbalance)
      sessionStorage.setItem('chainname', "metamask")
      sessionStorage.setItem('chainaccounts', accounts[0])
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: web3js.utils.toHex('0x89') }]
      });
      sessionStorage.setItem('chainname', "metamask")
      sessionStorage.setItem('chainaccounts', accounts[0])
    }
  }

  //ETHnetwork
  const ethnetworkbridge = async () => {
    console.log("ethnetwork")
    setdcxamountvalue(0)
    setdropdownvalue("")
    settokenbalance(0)
    setnetworklogo(ethimg)
    settokensymbol("ETH")
    setdepositamount(0)
    const maticbridgeOptions = [
      // { value: 1, label: "ETH" },
      { value: 1, label: "USDT (ERC20)" }
    ]
    setbridgesoption(maticbridgeOptions)
    if (chainname == "metamask" || chainname == "coinbase") {
      const chainId = 0x1;
      const currentchainId = window.ethereum.chainId;
      var web3js = new Web3(window.ethereum);
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      if (currentchainId != chainId) {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: web3js.utils.toHex(chainId) }]
        });

        var web3dchainjs = new Web3(config.valobit);
        const dchaincontract = new web3dchainjs.eth.Contract(erc20Abi, config.SEPOLIA_BATTOKEN_ADDRESS);
        const dchainbalance = await dchaincontract.methods.balanceOf(accounts[0]).call();
        const balancesdchain = web3js.utils.fromWei(dchainbalance, 'ether');
        const balancesdchainfixed = Number(balancesdchain).toFixed(8)
        var web3jsethbal = new Web3(config.valobit);
        const balance = await web3jsethbal.eth.getBalance(accounts[0])
        const balanceseth = web3jsethbal.utils.fromWei(balance, 'ether');
        const tokenbalance = parseFloat(balanceseth).toFixed(4);
        settokenbalance(tokenbalance)

        setbscbal(balancesdchainfixed)
        setbridgenetworklogo(dchainimg)
        setnetwork("ETH")
        setaccountaddress(accounts[0])
        setbridgenetworksymbol("TPN")
      }
      else {
        var web3dchainjs = new Web3(config.valobit);
        const dchaincontract = new web3dchainjs.eth.Contract(erc20Abi, config.SEPOLIA_BATTOKEN_ADDRESS);
        const dchainbalance = await dchaincontract.methods.balanceOf(accounts[0]).call();
        const balancesdchain = web3js.utils.fromWei(dchainbalance, 'ether');
        const balancesdchainfixed = Number(balancesdchain).toFixed(8)
        var web3js = new Web3(config.valobit);
        const balance = await web3js.eth.getBalance(accounts[0])
        const balanceseth = web3js.utils.fromWei(balance, 'ether');
        const tokenbalance = parseFloat(balanceseth).toFixed(4);
        settokenbalance(tokenbalance)

        setnetworklogo(ethimg)
        setbscbal(balancesdchainfixed)
        setbridgenetworklogo(dchainimg)
        setnetwork("ETH")
        setaccountaddress(accounts[0])
        setbridgenetworksymbol("TPN")
      }
    }
    else {
      const chainId = 0x1;
      const currentchainId = window.ethereum.chainId;
      var web3js = new Web3(window.ethereum);
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      sessionStorage.setItem('chainname', "metamask")
      sessionStorage.setItem('chainaccounts', accounts[0])
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: web3js.utils.toHex(chainId) }]
      });
      var web3dchainjs = new Web3(config.valobit);
      const dchaincontract = new web3dchainjs.eth.Contract(erc20Abi, config.SEPOLIA_BATTOKEN_ADDRESS);
      const dchainbalance = await dchaincontract.methods.balanceOf(accounts[0]).call();
      const balancesdchain = web3js.utils.fromWei(dchainbalance, 'ether');
      const balancesdchainfixed = Number(balancesdchain).toFixed(8)

      var web3ethbaljs = new Web3(config.valobit);
      const balance = await web3ethbaljs.eth.getBalance(accounts[0])
      const balanceseth = web3ethbaljs.utils.fromWei(balance, 'ether');
      const tokenbalance = parseFloat(balanceseth).toFixed(4);
      settokenbalance(tokenbalance)
      setnetworklogo(ethimg)
      setbscbal(balancesdchainfixed)
      setbridgenetworklogo(dchainimg)
      setnetwork("ETH")
      setaccountaddress(accounts[0])
      setbridgenetworksymbol("TPN")
    }
  }

  //max value
  const max = async () => {
    const depositpercent = ((tokenbalance * 99) / 100).toString();
    setdepositamount(depositpercent)
    getmaxvaluecontract(depositpercent)
  }

  //networkcheck
  const networkcheck = async (e) => {
    if (e === "MATIC") {
      maticnetworkbridge();
      setnetworkrpc(config.polygonurl)
      setnetworkchainid("137")
      $('.switch-tabs-section-poly').css('background', '#1b9ffe');
      $('.switch-tabs-section-poly').css('color', '#FFF');
      $('.switch-tabs-section-bsc').css('background', 'transparent');
      $('.switch-tabs-section-bsc').css('color', '#FFF');
    }
    else if (e === "ETH") {
      ethnetworkbridge();
      setnetworkrpc(config.web3dchainurl)
      setnetworkchainid("1")
      $('.switch-tabs-section-eth').css('background', '#1b9ffe');
      $('.switch-tabs-section-eth').css('color', '#FFF');
      $('.switch-tabs-section-bsc').css('background', 'transparent');
      $('.switch-tabs-section-bsc').css('color', '#FFF');
      $('.switch-tabs-section-poly').css('background', 'transparent');
      $('.switch-tabs-section-poly').css('color', '#FFF');
    }
    else if (e === "BSC") {
      metamask();
      $('.switch-tabs-section-bsc').css('background', '#1b9ffe');
      $('.switch-tabs-section-bsc').css('color', '#FFF');
      $('.switch-tabs-section-poly').css('background', 'transparent');
      $('.switch-tabs-section-poly').css('color', '#FFF');
      $('.switch-tabs-section-eth').css('background', 'transparent');
      $('.switch-tabs-section-eth').css('color', '#FFF');
    }
  }

  useEffect(() => {
    if (window.ethereum) {
      bridges();
      const reloadCount = sessionStorage.getItem('chainaccount');
      setaccountaddress(reloadCount)
    }
    else {
      toast.warn("Check Metamask is Installed", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, []);

  const handleKeyPress = async (event) => {
    console.log("")
    if (event.key === '-' || event.key === 'e') {
      event.preventDefault();
    }
  }


  const navigate = useNavigate();
  const [userAddress, setUserAddress] = useState("");
  const [userexiststatus, setuserexiststatus] = useState(false);

  const [recentUsers, setRecentUsers] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const { open, close } = useWeb3Modal();


  function isMobileDevice() {
    let check = false;
    (function (a) {
      if (
        'ontouchstart' in window || 'onmsgesturechange' in window ||
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);

    return check;
  }



  const walletdetails= async()=> {
    const ethereumAddress = wagmiConfig.ethereumAddress; 
    let result = localStorage.getItem("address");
    if(result){
      let address = result;
      let web3js = new Web3(backendConfig.rpcUrls);
      let sunflowerContract = new web3js.eth.Contract(
        sunflowerABI,
        config.sunflowerContract
      );
      let userexists = await sunflowerContract.methods
        .isUserExists(address)
        .call();
      if (userexists == true) {
        setuserexiststatus(true);
      } else {
        setuserexiststatus(false);
      }
    }
  };

  const userdashboardshow = async (userMetaAddress) => {
    let web3js = new Web3(backendConfig.rpcUrls);
   
    let sunflowerContract = new web3js.eth.Contract(
      sunflowerABI,
      config.sunflowerContract
    );
    let userexists = await sunflowerContract.methods
      .isUserExists(userMetaAddress)
      .call();
    if (userexists == true) {
      setuserexiststatus(true);
    } else {
      setuserexiststatus(false);
    }
  };

  const metamaskconnect = async() => {
    if (window.ethereum) {
        let backchain = backendConfig.chainId;
        let metchainid = window.ethereum.chainId;
        if(backchain == metchainid) {
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          localStorage.setItem("address", accounts[0]);
         
          let address = localStorage.getItem("address");
          walletdetails()
            setUserAddress(
              address.substring(0, 5) + "..." + address.substring(address.length - 4)
            );
            $("#joinnowbuttonhide").show();
        }
        else {
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          localStorage.setItem("address", accounts[0]);
          let address = localStorage.getItem("address");
          walletdetails()
            setUserAddress(
              address.substring(0, 5) + "..." + address.substring(address.length - 4)
            );
            $("#joinnowbuttonhide").show();
        }
    }
  }

  const connectAddress = async () => {

    const userDataString = localStorage.getItem("wagmi.store");
    const userData = JSON.parse(userDataString);

    const address = userData.state.data.account;
      let web3js = new Web3(backendConfig.rpcUrls);
      let sunflowerContract = new web3js.eth.Contract(sunflowerABI,config.sunflowerContract);
      let userexists = await sunflowerContract.methods.isUserExists(address).call();
      if (userexists == true) {
        setuserexiststatus(true);
      } else {
        setuserexiststatus(false);
      }
      window.location.reload();
      $("#joinnowbuttonhide").show();
  };

  const chains = [arbitrum, mainnet, polygon];
  const projectId = "1f0b9efb280db82bff5edc4689ffa76a";

  const { publicClient } = configureChains(chains, [
    w3mProvider({ projectId }),
  ]);

  
  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, chains }),
    publicClient,
  });
  const ethereumClient = new EthereumClient(wagmiConfig, chains);
 

  const disconnect = () => {
    localStorage.clear()
    window.location.reload()
  }
  


  useEffect(() => {
    let userMetaAddress = localStorage.getItem("wagmi.store");
    let userMeta = JSON.parse(userMetaAddress);
    let addr = userMeta.state.data.account;
    let metaaddress = localStorage.getItem("address");
    if (addr != null) {
      setUserAddress(
        addr.substring(0, 5) +
          "..." +
          addr.substring(addr.length - 4)
      );
      $("#joinnowbuttonhide").show();
      userdashboardshow(addr);
    }
    else if(metaaddress != null) {
      setUserAddress(
        metaaddress.substring(0, 5) +
          "..." +
          metaaddress.substring(metaaddress.length - 4)
      );
      $("#joinnowbuttonhide").show();
      userdashboardshow(metaaddress);
    }
    else {
      $("#joinnowbuttonhide").hide();
    }
    
    getRecentUser();
  }, []);


  const joinnowbutton = async () => {
    if (window.ethereum) {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      localStorage.setItem("address", accounts[0]);
      let web3js = new Web3(backendConfig.rpcUrls);
      let sunflowerContract = new web3js.eth.Contract(
        sunflowerABI,
        config.sunflowerContract
      );
      let userexists = await sunflowerContract.methods.isUserExists(accounts[0]).call();
      if (userexists == true) {
        navigate("/dashboard");
      } else {
        navigate("/network");
      }
    }
  };

  const bridgebutton = async() => {
    navigate("/bridge");
  }

  const seeMoreUsers = async () => {
    let pageNum = pageNumber+1;
    setPageNumber(pageNum);
      try {
      const response = await axios.get(`${backendConfig.BACKEND_URL}getdetails?page=${pageNum}`)
      if (response.status) {
        setRecentUsers(response.data.details);
      }
    } catch (error) {
      console.log(error, "error");
      // toast.error("Something went wrong");
    }
  };

  const getRecentUser = async () => {
    try {
      const response = await axios.get(`${backendConfig.BACKEND_URL}getdetails?page=1`);
      if (response.status) {
        setRecentUsers(response.data.details);
      }
    } catch (error) {
      console.log(error, "error");
      // toast.error("Something went wrong");
    }
  };
  const [showAllData, setShowAllData] = useState(false);





  return (
    <div className="App">
      {/* <Navigate useraddress={accountaddress} networkname={networkrpc ? networkrpc : config.bsctestnetrpcurl} networkchain={networkchainid ? networkchainid : "56"} /> */}
      <ToastContainer />
      <nav class="navbar navbar-expand-lg">
        <div class="container-fluid col-lg-11">
          <a class="navbar-brand" href="/">
            <img src={Logo} alt="logo" />
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon text-white"><AiOutlineAlignRight/></span>
          </button>
         
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto mb-2 mb-lg-0 gap-3">
          
              <li class="nav-item">
              {userAddress ? (
                  // User is connected, display their address
                  <>
                    <a class="nav-link main-nav-connect-button">
                      <IoWallet
                        className="me-2 main-nav-connect-button-icon"
                      />
                      {userAddress}
                    </a>
                  </>
                ) : (
                  // User is not connected, display "Connect wallet" button
                  <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle btn-connect-dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Connect Wallet
                    </button>
                    <ul class="dropdown-menu">
                      <li><a class="dropdown-item" onClick={metamaskconnect}><a class="">Metamask</a></a></li>
                      {/* <li><a class="dropdown-item">
                       <WagmiConfig config={wagmiConfig}>
                       <a class="" onClick={() => {
                          open();
                          connectAddress();
                        }}>
                          <IoWallet className="me-2 main-nav-connect-button-icon" />
                            Wallet connect
                          </a>
                        </WagmiConfig>
                        <Web3Modal
                          projectId={projectId}
                          ethereumClient={ethereumClient}
                        />
                      </a></li> */}
                    </ul>
                  </div>
                )}
              </li>
              <li>
                {userAddress ? (
                  <button class="main-nav-connect-button" onClick={disconnect} >Disconnect</button>
                ) : (
                 <a></a>
                )}
              </li>
                {/* {userAddress != "" ? (
                  <a
                    class="nav-link main-nav-connect-button"
                    onClick={connectwalletAddress}
                  >
                    <IoWallet className="me-2 main-nav-connect-button-icon" />
                    {userAddress}
                  </a>
                ) : (
                  <a
                    class="nav-link main-nav-connect-button"
                    onClick={connectAddress}
                  >
                    <IoWallet className="me-2 main-nav-connect-button-icon" />
                    Connect wallet
                  </a>
                )} */}
            </ul>
          </div>
        </div>
      </nav>
      <section className="py-lg-5 py-4 cfgfg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 switch-tabs-section">
              <div className="btn-group switch-tabs-btn-group">
                <button type="button" onClick={() => networkcheck("ETH")} className="btn switch-tabs-section-eth">
                  <img className="network-image" src={ethimg} alt="ETH Network" />Purchase by using ETH Network
                </button>
                <button type="button" onClick={() => networkcheck("BSC")} className="btn switch-tabs-section-bsc">
                  <img className="network-image" src={bscimage} alt="BSC Network" />Purchase by using BSC Network
                </button>
                <button type="button" onClick={() => networkcheck("MATIC")} className="btn switch-tabs-section-poly">
                  <img className="network-image" src={maticimg} alt="POLYGON Network" />Purchase by using POLYGON Network
                </button>
              </div>
            </div>
            <div className="col-lg-8 switch-banner-section pb-lg-5 mt-4">
              <p className="switch-banner-section-text-1 text-center">
                Buy 1 TPN at 0.009 USD
              </p>

              <div className="switch-banner-section-inner mb-3">
                <div className="switch-banner-amount-section">
                  <div className="row">
                    <div className="col-lg-12 text-start">
                      <select className="form-select" onChange={rewardset} value={dropdownvalue}>
                        <option value="" disabled selected hidden>Select option...</option>
                        {bridgesoption.map(item => (
                          <option key={item.value} value={item.label} className="option-value">
                            {item.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="switch-banner-section-inner">
                <div className="switch-banner-amount-section">
                  <div className="row">
                    <div className="col-lg-6 col-4 text-start">
                      <span className="switch-banner-section-text-2">
                        Amount You Spend
                      </span>
                    </div>
                    <div className="col text-end">
                      <span className="switch-banner-section-text-2">
                        Balance : <span className="switch-banner-section-text-3">
                          {tokenbalance != true ? tokenbalance : bscbal}
                        </span>
                      </span>
                      <br className="mobile-break" />
                      {/* <span data-bs-toggle="modal" data-bs-target="#infoModal">
                        <AiOutlineInfoCircle className="switch-banner-section-text-4" />
                      </span> */}
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-lg-6 col-6 text-start">
                      <input type="number" className="form-control form-control-amount" placeholder="Enter the amount" id="from_amount" onChange={(e) => getfromvaluecontract(e)} value={depositamount} onKeyPress={handleKeyPress} />
                    </div>
                    <div className="col amount-select-section">
                      <div className="row">
                        <div className="col-lg-4 col-4 text-lg-end">
                          <span>
                            <button type="button" className="btn-max" onClick={max}>
                              MAX
                            </button>
                          </span>
                        </div>
                        <div className="col text-end text-lg-center">
                          <span className="amount-select-from">
                            <img src={USDTimg} className="amount-select-image" alt="networklogo" /><span className="amount-select-name">{"USDT"}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                   { message &&<label style={{color:"red"}}>{message}</label>}
                <div className="switch-banner-centericon-section">
                  <div className="row py-2 justify-content-center text-center">
                    <div className="col">
                      <HiArrowDown className="centericon-swap" />
                    </div>
                  </div>
                </div>

                <div className="switch-banner-amount-section">
                  <div className="row">
                    <div className="col-lg-6 col-4 text-start">
                      <span className="switch-banner-section-text-2">
                        Amount You Buy
                      </span>
                    </div>
                    <div className="col text-end">
                      {/* <span className="switch-banner-section-text-2">
                        Balance : <span className="switch-banner-section-text-3">
                          {bscbal}
                        </span>
                      </span> */}
                      <br className="mobile-break" />
                      {/* <span data-bs-toggle="modal" data-bs-target="#infoModal2">
                        <AiOutlineInfoCircle className="switch-banner-section-text-4" />
                      </span> */}
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-lg-6 col-12 text-start">
                      <span className="form-control form-control-amount2" placeholder="0.00" id="to_amount">
                        <input type="number" className="form-control form-control-amount" placeholder="Enter the amount" id="to_amount" onChange={(e) => gettovaluecontract(e)}
                          value={dcxamountvalue} onKeyPress={handleKeyPress} />
                      </span>
                    </div>
                    <div className="col amount-select-section mt-2 mt-lg-0">
                      <div className="row flex-lg-row-reVRS">
                        <div className="col text-end text-lg-end">
                          <span className="amount-select-to">
                            <img src={bridgenetworklogo} className="amount-select-image" alt="networklogo" /><span className="amount-select-name">{bridgenetworksymbol}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="button-container-1 my-lg-4 my-4">
                  <span className="mas">Buy</span>
                  <button id="work" type="button" name="Hover" disabled={show} onClick={() => transfer(network)}>
                    BUY
                  </button>
                </div>
                <span style={{ "color": "red" }}>{showError}</span>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
}

export default Bridge;
let config = {}
let environment = "live"
if (environment === "local") {
    config = {
        ETH_BRIDGE_ADDRESS:"0x669e836f5f89ceC212aaCF992e8E4624Da4A3758",
        BSC_BRIDGE_ADDRESS: "0xB1F366b980Ed61581F07EA8e79c7c1ad2B1680b5",
        MATIC_BRIDGE_ADDRESS: "0xf9A9838Bc1044B3d8f8784c79FaB1a0846330886",
        web3dchainurl: "https://sepolia.infura.io/v3/129584eb745c42d99f931aff7215950a",
        valobit: "https://rpc.bkcscan.io/",
        bsctestnetrpcurl: "https://data-seed-prebsc-1-s2.binance.org:8545",
        polygonurl: "https://gateway.tenderly.co/public/polygon-mumbai",
        ETH_TOKEN_ADDRESS: "0x24fDE9cCc95cC43801AE7A71457AC9F7aE1809a9",
        BSC_TOKEN_ADDRESS: "0xe6Fc9E04AE701cf5BA109Ff7168A1510f5D3f768",
        MATIC_TOKEN_ADDRESS: "0x18f79a903a97606b0629dc974b772307dfb89617",
        SEPOLIA_BATTOKEN_ADDRESS: "0xB1F366b980Ed61581F07EA8e79c7c1ad2B1680b5",
        ADMIN_ADDRESS: "0xe3E94d586B9b5CA53E6b4B0b4f0DFcB151A4A305",
        BINANCE_API:"https://api.binance.us/api/v1/ticker/24hr",
        API_URL:"http://localhost:4000/web3matic", 
        APP_API_URL:"http://localhost:3000"
    }
}
else {
    config = {
        web3dchainurl: "https://mainnet.infura.io/v3/e0e84c49c4964f228f219731e87cffb1",
        valobit: "https://rpc.mnet.vblockchain.io/",
        bsctestnetrpcurl: "https://bsc-dataseed1.binance.org/",
        polygonurl: "https://polygon-rpc.com/",
        ETH_TOKEN_ADDRESS: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
        BSC_TOKEN_ADDRESS: "0x55d398326f99059fF775485246999027B3197955",
        MATIC_TOKEN_ADDRESS: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
        SEPOLIA_BATTOKEN_ADDRESS: "0xb9A219631Aed55eBC3D998f17C3840B7eC39C0cc",
        ADMIN_ADDRESS: "0xe3E94d586B9b5CA53E6b4B0b4f0DFcB151A4A305",
        BINANCE_API:"https://api.binance.us/api/v1/ticker/24hr",
        API_URL:"https://buyidolauchpad-api.d-ecosystem.io/web3matic",
        APP_API_URL:"https://buyidolauchpad-api.d-ecosystem.io/"
    }
}

export default config;
let config = {};
let environment = "live";
if (environment === "local") {
  config = {
    BKC_Contract: "0xB1F366b980Ed61581F07EA8e79c7c1ad2B1680b5", 
    POLYGON_Contract: "0x538ae099d56f35fad0b4d66c904cfc03df8961d4",
    BKC_MLMContract: "0x6ce43DC451E450b256462a239E796A415BB56376",
    BACKEND_URL: "http://35.154.135.127:5000/", 
    FRONTEND_URL: "http://3.110.168.187/",
    chainId: "0x54d",
    polygon_chainId: "0x13881",
    rpcUrls: "https://rpc.bkcscan.io/",
    polygon_rpcUrls: "https://rpc-mumbai.maticvigil.com",
    metmaskurl: "https://metamask.io/download/",
    BKC_ContarctLink: "https://bkcscan.io/",
    BSC_ContarctLink: "https://testnet.bscscan.com/tx/",
    polygon_ContarctLink: "https://mumbai.polygonscan.com",
  };
} else if (environment === "deployment") {
  config = {
    BKC_Contract: "0xb9A219631Aed55eBC3D998f17C3840B7eC39C0cc",
    POLYGON_Contract: "0x79E2913994c5378661283b63Aa5064991f738EA9",
    BKC_MLMContract: "0x083bA4366950CE64d89F1Dd961aB32cadde18524",
    BACKEND_URL: "https://api.valobitfl.com/",
    FRONTEND_URL: "https://valobitfl.com/",
    chainId: "0x9e7",
    polygon_chainId: "0x89",
    rpcUrls: "https://rpc.mnet.vblockchain.io/",
    polygon_rpcUrls: "https://polygon-rpc.com/",
    metmaskurl: "https://metamask.io/download/",
    BKC_ContarctLink: "https://valobitscan.io/",
    BSC_ContarctLink: "https://testnet.bscscan.com/tx/",
    polygon_ContarctLink: "https://polygonscan.com/",
    CHAIN_NAME: 'Valobit',
    CURRENCY_NAME:"V-BIT",
    CURRENCY_SYMBOL:"V-BIT",
    CURRENCY_DECIMALS: 18,
  };
} else {
  config = {
    BKC_Contract: "0xb9A219631Aed55eBC3D998f17C3840B7eC39C0cc", 
    POLYGON_Contract: "0x79E2913994c5378661283b63Aa5064991f738EA9",
    BKC_MLMContract: "0x083bA4366950CE64d89F1Dd961aB32cadde18524",
    BACKEND_URL: "https://buyidolauchpad-api.d-ecosystem.io/", 
    FRONTEND_URL: "https://buyidolauchpad.d-ecosystem.io/",
    chainId: "0x9e7",
    polygon_chainId: "0x89",
    rpcUrls: "https://bsc-dataseed1.binance.org/",
    metmaskurl: "https://metamask.io/download/", 
    BKC_ContarctLink: "https://valobitscan.io/",
    BSC_ContarctLink: "https://bscscan.com/tx/",
    polygon_ContarctLink: "https://polygonscan.com/",
    CHAIN_NAME: 'Valobit',
    CURRENCY_NAME:"V-BIT",
    CURRENCY_SYMBOL:"V-BIT",
    CURRENCY_DECIMALS: 18,
  };
}

export default config;
